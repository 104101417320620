.figure {
  display: inline-block;
}

.figure-caption {
  @include typography-caption;
}

.figure-img {
  line-height: 1;
  margin-bottom: $figure-img-margin-y;
}

.img-fluid {
  @include img-fluid;
}

.img-thumbnail {
  @include img-fluid;

  box-shadow: $thumbnail-box-shadow;
}
