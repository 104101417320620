header.navbar {
  background-image: url('../img/header_bg.png'), linear-gradient(90deg, #0a6fc2 0%, #2196f3 100%);
  background-position: top left, center;
  background-repeat: no-repeat;
  background-size: 500px 100px, cover;

  .navbar-brand {
    font-size: 2.5rem;
    letter-spacing: -2px;
    line-height: 1;
    font-weight: normal;
    align-items: baseline;
    height: 2.5rem;
    text-shadow: 0 0 2px #000;

    b {
      font-weight: bold;
    }

    small {
      padding-left: .75rem;
      font-size: 1.2rem;
      letter-spacing: normal;
    }
  }

  .dropdown-menu {
    .dropdown-item {
      i {
        font-size: 20px;
        width: 2rem;
      }
    }
  }
}
