::-webkit-scrollbar-track {
  background: $body-bg;
}

::-webkit-scrollbar-thumb {
  background: $scrollbar-color;
}

::-webkit-scrollbar-corner {
  background: $body-bg;
}
