// basic
.select2-container {
  display: block;

  *:focus {
    outline: 0;
  }
}

// input-group
.input-group .select2-container--bootstrap4 {
  flex-grow: 1;
}

// for prepended input
.input-group-prepend ~ .select2-container--bootstrap4 .select2-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

// for appended input
.input-group > .select2-container--bootstrap4:not(:last-child) .select2-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

// search box
.select2-search--dropdown .select2-search__field {
  background: transparent;
  border: 0;
}

// no result message
.select2-container.select2-container--bootstrap4 {
  // input box
  .select2-selection {
    background-color: transparent;
    border-radius: 4px;
    box-shadow: none;
    width: 100%;
  }

  .select2-search--inline .select2-search__field {
    background: transparent;
  }

  // focused input box
  &.select2-container--focus {
    .select2-selection {
      outline: 0;
    }
  }

  // when open, hide bottom border
  &.select2-container--open {
    &.select2-container--above .select2-selection {
      border-top: none;
      @include border-top-radius(0);
    }

    &.select2-container--below .select2-selection {
      border-bottom: none;
      @include border-bottom-radius(0);
    }
  }

  // disabled input box
  &.select2-container--disabled,
  &.select2-container--disabled.select2-container--focus {
    .select2-selection {
      cursor: not-allowed;
      box-shadow: none;
    }

    .select2-search__field {
      background-color: transparent;
    }
  }

  // dropdown
  .select2-dropdown {
    border-radius: 0; // overwrite select2.css

    // dropdown opened below
    &.select2-dropdown--below {
      border-top: none;
      @include border-bottom-radius(4px);
    }

    // dropdown opened above
    &.select2-dropdown--above {
      @include border-top-radius(4px);
    }
  }

  // for <optgroup>
  .select2-results__option[role=group] {
    padding: 0;

    .select2-results__options--nested .select2-results__option {
      padding-left: 1em;
    }
  }

  // fixes vertical overflow
  .select2-results > .select2-results__options {
    max-height: 15em;
    overflow-y: auto;
  }

  .select2-results__group {
    padding: 6px;
    display: list-item;
  }

  // all clear button
  .select2-selection__clear {
    width: 0.9em;
    height: 0.9em;
    line-height: 0.75em;
    padding-left: 0.15em;
    margin-top: 0.7em;
    border-radius: 100%;

    float: right;
    margin-right: 0.3em;
  }

  .select2-selection--single {
    // height of input box
    height: inherit;
    padding: $textfield-padding-y $textfield-padding-x;

    .select2-selection__rendered {
      padding-left: 0;
      padding-right: 0;
    }

    // placeholder
    .select2-selection__placeholder {
      // #757575 is same color of placeholder (on Chrome)
      color: #757575;
    }

    // arrow
    .select2-selection__arrow {
      position: absolute;
      top: 50%;
      right: 3px;
      width: 20px;

      b {
        top: 60%;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        width: 0;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
      }
    }
  }

  .select2-selection--multiple {
    // height of input box
    min-height: $textfield-height !important;

    // input box
    .select2-selection__rendered {
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      padding: 4px 8px;
      width: 100%;
    }

    // each selected item in input box
    .select2-selection__choice {
      @include border-radius(2px);
      padding: 0;
      padding-right: 5px;
      cursor: pointer;
      float: left;
      margin-top: 0.3em;
      margin-right: 5px;
    }

    // x button of each selected item in input box
    .select2-selection__choice__remove {
      font-weight: bold;
      margin-left: 3px;
      margin-right: 1px;
      padding-right: 3px;
      padding-left: 3px;
      float: left;
    }

    // all clear button
    .select2-selection__clear {
      // overwrite layout.scss
      float: none;
      margin-right: 0;

      position: absolute !important;
      top: 0;
      right: 0.7em;
    }
  }
}
