// Material design does not have specs for striped progress bars
// so striped progress bars have been styled according to the "buffer" variation of linear indicator
// (https://material.google.com/components/progress-activity.html#progress-activity-types-of-indicators)

.progress {
  display: flex;
  height: $progress-height;
  overflow: hidden; // force rounded corners by cropping it
  line-height: 0;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
}
