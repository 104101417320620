.table {
  border: 0;
  margin-bottom: $table-margin-y;
  max-width: 100%;
  width: 100%;

  td,
  th {
    line-height: $table-line-height;
    padding-right: $table-cell-padding-x;
    padding-left: $table-cell-padding-x;
    vertical-align: top;

    &:first-child {
      padding-left: $table-cell-padding-x-alt;
    }

    &:last-child {
      padding-right: $table-cell-padding-x-alt;
    }
  }

  tbody {
    td,
    th {
      font-size: $table-tbody-font-size;
      font-weight: $table-tbody-font-weight;
      height: $table-tbody-cell-height;
      padding-top: $table-tbody-padding-y;
      padding-bottom: $table-tbody-padding-y;
    }
  }

  tfoot {
    td,
    th {
      font-size: $table-tfoot-font-size;
      font-weight: $table-tfoot-font-weight;
      height: $table-tfoot-cell-height;
      padding-top: $table-tfoot-padding-y;
      padding-bottom: $table-tfoot-padding-y;
    }
  }

  thead {
    td,
    th {
      font-size: $table-thead-font-size;
      font-weight: $table-thead-font-weight;
      height: $table-thead-cell-height;
      padding-top: $table-thead-padding-y;
      padding-bottom: $table-thead-padding-y;
    }
  }

  .card > & {
    &:first-child {
      &,
      > :first-child,
      > :first-child > tr:first-child {
        @include border-top-radius($card-border-radius);
      }

      > :first-child > tr:first-child {
        // stylelint-disable selector-max-compound-selectors
        td,
        th {
          &:first-child {
            border-top-left-radius: $card-border-radius;
          }

          &:last-child {
            border-top-right-radius: $card-border-radius;
          }
        }
        // stylelint-enable
      }
    }

    &:last-child {
      &,
      > :last-child,
      > :last-child > tr:last-child {
        @include border-bottom-radius($card-border-radius);
      }

      > :last-child > tr:last-child {
        // stylelint-disable selector-max-compound-selectors
        td,
        th {
          &:first-child {
            border-bottom-left-radius: $card-border-radius;
          }

          &:last-child {
            border-bottom-right-radius: $card-border-radius;
          }
        }
        // stylelint-enable
      }
    }
  }

  > :first-child > tr:first-child {
    td,
    th {
      border-top: 0;
    }
  }
}

.table-borderless {
  td,
  th,
  .table {
    border: 0;
  }
}

.table-bordered {
  .card > & {
    border: 0;
  }
}

.table-sm {
  td,
  th {
    padding-right: $table-sm-cell-padding-x;
    padding-left: $table-sm-cell-padding-x;

    &:first-child {
      padding-left: $table-sm-cell-padding-x-alt;
    }

    &:last-child {
      padding-right: $table-sm-cell-padding-x-alt;
    }
  }

  tbody {
    td,
    th {
      height: $table-sm-tbody-cell-height;
      padding-top: $table-sm-tbody-padding-y;
      padding-bottom: $table-sm-tbody-padding-y;
    }
  }

  tfoot {
    td,
    th {
      padding-top: $table-sm-tfoot-padding-y;
      padding-bottom: $table-sm-tfoot-padding-y;
    }
  }

  thead {
    td,
    th {
      height: $table-sm-thead-cell-height;
      padding-top: $table-sm-thead-padding-y;
      padding-bottom: $table-sm-thead-padding-y;
    }
  }
}

.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint);
    $infix: breakpoint-infix($next);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        overflow-x: auto;
        width: 100%;
        -ms-overflow-style: -ms-autohiding-scrollbar;
      }
    }
  }
}
