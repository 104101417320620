// stylelint-disable declaration-no-important

// Border

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

// Border radius

.rounded {
  @include border-radius($border-radius);
}

.rounded-0 {
  border-radius: 0;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-top {
  @include border-top-radius($border-radius);
}

.rounded-right {
  @include border-right-radius($border-radius);
}

.rounded-bottom {
  @include border-bottom-radius($border-radius);
}

.rounded-left {
  @include border-left-radius($border-radius);
}
