// Blockquote

.blockquote {
  border-left: $blockquote-border-width solid $blockquote-border-color;
}

.blockquote-footer {
  color: $blockquote-small-color;
}

// Emphasis

mark,
.mark {
  background-color: $mark-bg;
  color: $mark-color;
}

// Headings - bootstrap classes

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $headings-color;
}

// Hr

hr {
  border-top: $hr-border-width solid $hr-border-color;
}
