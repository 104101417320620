@each $color, $values in $theme-colors {
  .badge-#{$color} {
    background-color: theme-color($color);
    color: color-yiq(theme-color($color));

    &[href] {
      @include active-focus-hover {
        background-color: theme-color-dark($color);
        color: color-yiq(theme-color-dark($color));
        text-decoration: none;
      }
    }
  }
}
