/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('lib/material-icons/material-icons.woff2') format('woff2');
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('lib/material-icons/material-icons-outlined.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;

  &.outlined {
    font-family: 'Material Icons Outlined';
  }
}

.material-icons {
  @include reset-material-icons;

  &.lg {
    @include reset-material-icons($material-icon-size-lg);
  }

  &.sm {
    @include reset-material-icons($material-icon-size-sm);
  }

  &.inline {
    line-height: 1 !important;
  }
}

.material-icons-inline {
  font-size: inherit;
  line-height: 1;
}

