.progress {
  &.h-5 {
    height: 5px;
  }

  &.h-10 {
    height: 10px;
  }

  &.h-15 {
    height: 15px;
  }

  &.h-20 {
    height: 20px;
  }
}
