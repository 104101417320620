.fc {
  &.fc-theme-standard {
    .fc-scrollgrid {
      border: 0;
    }

    .fc-scrollgrid-section-sticky > * {
      background: none;
    }
  }
}
