body.embed {
  background: transparent !important;
  min-height: auto;

  &.ondemand {
    overflow: hidden;
  }
}

body.embed-social {
  min-height: auto;
  padding: 1rem;
}
