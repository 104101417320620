@at-root {
  $theme: 'light';
  @import 'common';
}

@at-root {
  $theme: 'light';
  @import 'common-colors';
}

[data-theme="dark"] {
  $theme: 'dark';
  @import 'common-colors';
}

@media screen and (prefers-color-scheme: dark) {
  [data-theme="browser"] {
    $theme: 'dark';
    @import 'common-colors';
  }
}
