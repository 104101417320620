.breadcrumb {
  @include border-radius($breadcrumb-border-radius);

  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: $breadcrumb-margin-y;
  min-height: $breadcrumb-height;
  padding: $breadcrumb-padding-y $breadcrumb-padding-x;
}

.breadcrumb-item {
  @include transition-standard(color);

  align-items: center;
  display: flex;

  &.active {
    font-weight: bolder;
  }

  a {
    text-decoration: none;
  }

  + .breadcrumb-item {
    margin-left: $breadcrumb-inner-spacer-x;

    &::before {
      @include setup-material-icons-bg;
      @include material-icons-bg($breadcrumb-item-icon);

      display: inline-block;
      margin-right: $breadcrumb-inner-spacer-x;
    }
  }
}
