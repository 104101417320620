.snackbar {
  background-color: $snackbar-bg;
  color: $snackbar-color;
}

.snackbar-btn {
  color: $snackbar-btn-color;

  @include focus-hover {
    color: $snackbar-btn-color-hover;
  }
}
