.progress {
  background-color: $progress-bg;
  color: $white-secondary;

  @each $color, $values in $theme-colors {
    &.bg-#{$color} {
      background-color: theme-color-light($color);

      .progress-bar {
        background-color: theme-color($color);
      }
    }
  }
}

.progress-bar {
  background-color: $progress-bar-bg;
}
