input, textarea {
  &.text-preformatted {
    font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
  }
}

.form {
  fieldset {
    margin-bottom: 1rem;

    div.fieldset-legend,
    legend.col-form-label {
      p {
        margin-bottom: 0.5rem;
      }

      border-bottom: 2px dotted;
      margin-bottom: 0.75rem;
    }

    .row {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .form-group {
      & > label {
        margin-bottom: .5rem !important;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    label.required {
      &:after {
        content: " *";
        color: $material-color-red-700;
      }
    }
  }

  & > fieldset:last-of-type {
    margin-bottom: 0;
  }
}
